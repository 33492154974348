.main {
  position: relative;
  height: 100%;
  background: linear-gradient(334deg, #1C2B5F -0.39%, rgba(0, 14, 137, 0.13) 100%);
  background-color: black;
  overflow: hidden;
  margin: auto;
}

.container {
  max-width: 1500px;
  margin: auto;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}

html {
  background-color: #1C2B5F;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20rem;
  height: 20rem;
  padding: 90px;
}

.loadingSvg {
  z-index: 999;
  animation: zoom-in-zoom-out 1.4s ease-in-out infinite;
}

.pulse-ring {
  position: absolute;
  top: -50;
  left: -50;
  bottom: -50;
  right: -50;
  content: "";
  width: 50%;
  height: 50%;
  background-color: #FFFFFF50;
  border-radius: 50%;
  animation: pulsate ease-in-out infinite 1.4s;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: scale(1.75, 1.75);
    opacity: 0;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
