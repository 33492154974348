@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');

@font-face {
  font-family: 'AlienRobot';
  src: local('AlienRobot'),
       url('./public/fonts/alien_robot/AlienRobot.ttf') format('truetype');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}

/* Alien Robot - OTF */
@font-face {
  font-family: 'AlienRobot';
  src: local('AlienRobot'),
       url('./public/fonts/alien_robot/AlienRobot.otf') format('opentype');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}

html {
  font-size: 16px;
  background: #1C2B5F;
  margin: 0;
  overscroll-behavior: none;
} /*16px*/

body {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.75;
  margin: 0;
  overflow-y: scroll;
  overscroll-behavior: none;
}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

small, .text_small {font-size: 0.8rem;}

.wallet-icon {
  margin-top: 0.3rem;
}

.protect-inputbox {
  margin-left: auto !important;
  margin-right: 7px !important;
  max-width: 77% !important;
}



@media only screen and (max-width: 499px) {
  .body {
    overscroll-behavior: auto !important;
  }

  .html {
    overscroll-behavior: auto !important;
  }

  .walletbuttonmodal-buttons-flexbox {
    flex-direction: column;
  }

  .logout-button {
    margin-bottom: 15px;
  }

  .wallet-icon {
    display: none;
  }

  .protect-inputbox {
    max-width: 100% !important;
    text-align: left !important;
  }
}

@media only screen and (max-width: 334px) {
  .header-text {
    font-size: var(--h2-font-size) !important;
  }
}

@media only screen and (min-width: 797px) {
  .menu-button {
    width: 13.25rem !important;
  }
}

@media only screen and (max-width: 796px) {
  .menu-button {
    width: auto !important;
  }
}

.wallet-div:hover {
  filter: brightness(80%);
  cursor: pointer;
}

/* TODO update the css below once we have more network support for onboarding bridging*/
.network-div:hover {
  filter: brightness(80%);
  cursor: pointer;
}

.network-div:focus {
  border: 1px solid var(--components-primary-blue);
}

.network-div-disabled {
  filter: brightness(45%);
}

.network-div-disabled:hover {
  cursor: not-allowed;
}


:root {
  /* global colors */
  --background-blue: rgba(0, 111, 223, 0.15);
  --border-blue: rgba(184, 154, 232, 0.4);
  --table-background-blue: #0F255B;
  --components-primary-blue: #007FFF;
  --text-primary-white: #FAFAFA;
  --text-secondary-gray: #D2D2D2;
  --text-error: #DC143C;
  --divider-blue: #192C64; /* update this */
  --modal-blue: #011857; /* update this */
  --button-opaque-white: #ffffff15;
  --onboarding-color: #01072A;
  --banner-gradient-purple: transparent;
  --overlay-modal-black: var(--chakra-colors-blackAlpha-800);

  /* card radius */
  --card-radius: 0.75rem;
  --modal-radius: 15px;

  /* left && right page padding */
  --page-padding: 2rem;

  /* menu items padding */
  --menu-item-left-padding: 1rem;

  /* button padding */
  --button-px: 1rem;
  --button-py: 0.1rem;

  /* button left margin */
  --button-lm: 0.5rem;

  /* header margin font line-height */
  --header-font-weight: 600;
  --header-line-height: 1.3;

  /* h1 attributes */
  --h1-font-size: 2.25rem;
  --h1-mt: 0;

  /* h2 attributes */
  --h2-font-size: 1.25rem;

  /* h3 attributes */
  --h3-font-size: 1.15rem;

  /* p attributes */
  --p-font-size: 1rem;

  /* p2 attributes */
  --p2-font-size: 0.8rem;

  /* chevron size */
  --chevron-size: 0.4rem;

  /* grid item bottom margin */
  --grid-item-bm: 1.75rem;

  /* vertical grid spacing */
  --row-gap: 3.5rem;

  /* horizontal grid spacing */
  --column-gap: 2.5rem;

  /* section bottom margin */
  --section-bm: 4.5rem;
}
